
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { BotDomain, DeepPartial, DomainData, EagleCRMDomainData } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type EagleCRMSettings = Pick<EagleCRMDomainData, 'clientId' | 'clientSecret'>;

@Component({
  name: 'eagle-crm-settings-card',
})
export default class EagleCRMSettingsCard extends mixins(BotMixin) {
  eagleCRMSettings: EagleCRMSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.eagleCRMSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.RealEstate]: {
              eagleCRM: this.eagleCRMSettings,
            },
          } as DeepPartial<DomainData>,
        },
      });
      this.$notify.success('Successfully updated EagleCRM-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating EagleCRM-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.eagleCRMSettings = this.resetValue();
  }

  private resetValue(): EagleCRMSettings {
    return {
      clientId: this.bot.domainData[BotDomain.RealEstate]?.eagleCRM?.clientId || '',
      clientSecret: this.bot.domainData[BotDomain.RealEstate]?.eagleCRM?.clientSecret || '',
    };
  }
}
