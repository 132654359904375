
import { BotRealEstateApi } from '@/api/BotRealEstateApi';
import RealEstateListingsListItem from '@/components/real-estate/RealEstateListingsListItem.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import {
  Bot,
  BotDomain,
  FeaturedListingInfo,
  GenericListing,
  GenericListingType,
  LoadPaginatedListingsOptions,
  RealEstateDomainDataContent,
} from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'real-estate-manual-listings-list-card',
  components: { RealEstateListingsListItem },
})
export default class RealEstateManualListingsListCard extends mixins(BotMixin) {
  @Prop({ required: false, type: Boolean, default: false })
  readonly scrollable!: boolean;

  selectedListingType: GenericListingType | 'all' = 'all';

  featuredListingInfoList: FeaturedListingInfo[] = [];

  isLoading = false;
  isSubmitting = false;

  loadedListings: GenericListing[] = [];

  get botFeaturedListingInfoList(): FeaturedListingInfo[] | undefined {
    return this.bot.domainData?.[BotDomain.RealEstate]?.featuredListingsList;
  }

  isListingFeatured(listing: GenericListing): boolean {
    return this.featuredListingInfoList.some(
      (info) => info.listingId === listing.listingId && info.source === listing.source,
    );
  }

  onListingFeaturedChange(listing: GenericListing, isFeatured: boolean) {
    const infoIndex = this.featuredListingInfoList.findIndex(
      (info) => info.listingId === listing.listingId && info.source === listing.source,
    );
    if (isFeatured && infoIndex < 0) {
      this.featuredListingInfoList.push({
        listingId: listing.listingId,
        source: listing.source,
      });
    } else if (!isFeatured && infoIndex >= 0) {
      this.featuredListingInfoList.splice(infoIndex, 1);
    }
  }

  async submitChanges() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.RealEstate]: {
              ...(this.bot.domainData[BotDomain.RealEstate] || {}),
              featuredListingsList: this.featuredListingInfoList,
            } as RealEstateDomainDataContent,
          },
        },
      });
      this.$notify.success('Successfully updated featured listings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating featured listings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { deep: true, immediate: true })
  async onBotChange(bot: Bot, prev?: Bot) {
    if (!prev || bot.id !== prev.id) {
      this.reset();
      await this.loadListings();
    }
  }

  private reset() {
    this.loadedListings = [];
  }

  async onDeleted() {
    this.reset();
    await this.loadListings();
  }

  async loadListings(): Promise<void> {
    this.isLoading = true;
    try {
      const filter: LoadPaginatedListingsOptions = {
        source: 'manual-entry',
      };

      const listings = await BotRealEstateApi.getListings(this.bot.id, {
        filter,
        limit: 50
      });
      this.loadedListings.push(...listings);
    } catch (e) {
      this.$notify.error({
        title: 'Loading listings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
