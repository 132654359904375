
import ListingManualEntryCard from '@/components/real-estate/ListingManualEntryCard.vue';
import RealEstateManualListingsListCard from '@/components/real-estate/RealEstateManualListingsListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-real-estate-manual-entry',
  components: { ListingManualEntryCard, RealEstateManualListingsListCard },
})
export default class ProjectRealEstateManualEntry extends mixins(ProjectViewMixin) {}
