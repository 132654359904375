
import { BotRealEstateApi } from '@/api/BotRealEstateApi';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { CreateManualEntryGenericListing } from 'ignite360-core';
import ListingManualEntryForm from './ListingManualEntryForm.vue';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'listing-manual-entry-card',
  components: { ListingManualEntryForm },
})
export default class ListingManualEntryCard extends mixins(BotMixin) {
  listing: CreateManualEntryGenericListing = this.resetValue();

  isSubmitting = false;

  image: File | null = null;
  imageSquare: File | null = null;

  get dataHasChanged(): boolean {
    return !isEqual(this.listing, this.resetValue());
  }

  get canSubmit(): boolean {
    return (
      !!this.listing.address.displayAddress &&
      !!this.listing.address.suburb &&
      !!this.image &&
      !!this.listing.url
    );
  }

  onInput(listing: CreateManualEntryGenericListing) {
    this.listing = { ...listing };
  }

  onImageInput(images: { square: File, horizontal: File }) {
    this.image = images.horizontal;
    this.imageSquare = images.square;
  }

  async onSubmit() {
    if (!this.canSubmit) {
      return;
    }
    this.isSubmitting = true;

    try {
      await BotRealEstateApi.addListing(this.bot.id, this.listing, { square: this.imageSquare, horizontal: this.image });

      this.$emit('added');
      this.$notify.success('Successfully added listing');
      this.reset();

      // Workaround for now to show changes
      window.location.reload();
    } catch (e) {
      this.$notify.error({
        title: 'Could not add listing',
        description: GENERIC_ERROR_DESCRIPTION,
      });
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.image = null;
    this.imageSquare = null;
    this.listing = this.resetValue();
  }

  private resetValue(): CreateManualEntryGenericListing {
    return {
      headline: '',
      type: 'buy',
      address: {
        displayAddress: '',
        state: '-', // Not needed
        suburb: '', // -> Needed for bot
        postcode: '-', // Not needed
      },
      url: '',
      inspections: [],
    };
  }
}
