
import EagleCrmSettingsCard from '@/components/real-estate/EagleCRMSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-real-estate-eagle-crm',
  components: { EagleCrmSettingsCard },
})
export default class ProjectRealEstateEagleCRM extends mixins(ProjectViewMixin) {}
